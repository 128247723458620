<div class="pert_6">
  <div class="container">
    <div class="row">
      <div class="col-sm-6 col-12">
        <form [formGroup]="contactForm" (submit)="submitContact()">
          <h2>Reach Out to us !</h2>
          <p>Please contact us for any questions or support.</p>
          <div *ngIf="showAlert" class="alert alert-success">Your query has been successfully submitted. You will be notified very shortly.</div>
          <div class="fm">
            <input type="text" placeholder="Name" formControlName="name">
          </div>
          <div class="fm">
            <input type="text" placeholder="Email" formControlName="email">
          </div>
          <div class="fm">
            <input type="text" placeholder="Contact Number" formControlName="phone">
          </div>
          <div class="fm">
            <textarea placeholder="Message" rows="3" formControlName="message"></textarea>
          </div>
          <div class="fm-btn">
            <input type="submit" value="Submit">
          </div>  
        </form>
      </div>
      <div class="col-sm-6"></div>
    </div>
  </div>
</div>
