import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "@environments/environment";
import { map } from "rxjs/operators";
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor(private http: HttpClient) { }

  getContentBySlug(slug) {
    return this.http.get<any>(`${environment.apiBaseUrl}/static/page/get/${slug}`)
  }

  contactUs(params) {
  	return this.http.post<any>(`${environment.apiBaseUrl}/support/contact/send`, params);
  }
}
