import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "@environments/environment";
import { map } from "rxjs/operators";
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RentalService {

  constructor(private http: HttpClient) { }

  getMyRental(productId?) {
    return this.http.get<any>(`${environment.apiBaseUrl}/order/rental/list?cancelled=yes&item=${productId}`)
  }

  applyCoupon(reqData) {
    return this.http.post<any>(`${environment.apiBaseUrl}/discount/promocode/apply`, reqData)
  }

  getRentalDetails(id) {
    return this.http.get<any>(`${environment.apiBaseUrl}/order/rental/detail/` + id)
  }

  cancelBooking(reqData, id) {
    return this.http.post<any>(`${environment.apiBaseUrl}/order/rental/cancel/` + id, reqData)
  }

  preparePickup(id) {
    return this.http.post<any>(`${environment.apiBaseUrl}/order/rental/prepare-pickup/` + id, {})
  }

  verifyPickup(reqData, id) {
    return this.http.post<any>(`${environment.apiBaseUrl}/order/rental/verify-pickup/` + id, reqData)
  }

  postCustomerReview(reqData) {
    return this.http.post<any>(`${environment.apiBaseUrl}/rating/review/customer/create`, reqData)
  }

  getMyEarning(params?) {
    return this.http.get<any>(`${environment.apiBaseUrl}/order/reports/earning?fromDate=${params.startDate}&toDate=${params.endDate}`)
  }

  doReport(reqData, id) {
    return this.http.post<any>(`${environment.apiBaseUrl}/order/rental/report-rental/${id}`, reqData)
  }

  acceptBooking(id) {
    return this.http.post<any>(`${environment.apiBaseUrl}/order/rental/accept/` + id, {})
  }
  
  rejectBooking(id) {
    return this.http.post<any>(`${environment.apiBaseUrl}/order/rental/reject/` + id, {})
  }

  getBookingModificationRequest(id) {
    return this.http.get<any>(`${environment.apiBaseUrl}/order/order/modification-request/` + id)
  }

  acceptBookingModification(id) {
    return this.http.post<any>(`${environment.apiBaseUrl}/order/rental/accept-modification/` + id, {})
  }

  rejectBookingModification(id) {
    return this.http.post<any>(`${environment.apiBaseUrl}/order/rental/reject-modification/` + id, {})
  }
}
