import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "@environments/environment";
import { map } from "rxjs/operators";
import { BehaviorSubject, Observable } from 'rxjs';

const headeOption = {
  headers : new HttpHeaders({'Content-Type':'application/json'}),
};

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  constructor(private http: HttpClient) { }

  createRequest(reqData) {
    return this.http.post<any>(`${environment.apiBaseUrl}/support/ticket/create`, reqData, headeOption)
  }

  getParentCategories(): Observable<any>{
    return this.http.get<any>(`${environment.apiBaseUrl}/support/support-category/list`, headeOption)
  }

  getSubCategories(id) {
    return this.http.get<any>(`${environment.apiBaseUrl}/support/support-category/list?parent=` + id, headeOption)
  }

  getTickets() {
    return this.http.get<any>(`${environment.apiBaseUrl}/support/ticket/list`, headeOption)
  }

  getTicketDetail(id) {
    return this.http.get<any>(`${environment.apiBaseUrl}/support/ticket/detail/${id}`, headeOption)
  }

  sendReply(params, id) {
    return this.http.post<any>(`${environment.apiBaseUrl}/support/ticket/reply/${id}`, params, headeOption)
  }

  // FAQ
  getFaq(query) {
    return this.http.get<any>(`${environment.apiBaseUrl}/faq/filter-faq?scope=CUSTOMER&query=${query}`, headeOption)
  }
}
