import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReachOutComponent } from "./reach-out.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [ReachOutComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [ReachOutComponent],
  schemas: []
})
export class ReachOutModule { }
