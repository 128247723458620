import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "@environments/environment";
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  subscriptionBaseUrl: string;

  constructor(private http: HttpClient) { 
    this.subscriptionBaseUrl=`${environment.apiBaseUrl}/subscription`;
  }

  subPlanList(){
    return this.http.get<any>(`${this.subscriptionBaseUrl}/plan/filter`).pipe(
      map((result: any) => {
        return result.data;
    }));
  }

  planDetails(id:string){
    return this.http.get<any>(`${this.subscriptionBaseUrl}/plan/detail/${id}`).pipe(
      map((result: any) => {
        return result.data;
    }));
  }

  subscribe(data: any) {
    var reqOpts = {
      params: new HttpParams()
    };
    return this.http.post<any>(`${this.subscriptionBaseUrl}/subscription/subscribe`, data, reqOpts).pipe(
      map((res: any) => {
        return res.data;
    }));
  }
}
