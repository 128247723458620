import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, FormControl, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { ContentService } from '@services/content.service';

@Component({
  selector: 'app-reach-out',
  templateUrl: './reach-out.component.html',
  styleUrls: ['./reach-out.component.css']
})
export class ReachOutComponent implements OnInit {
	contactForm: FormGroup;
  showAlert: boolean = false;

  constructor(
  	private fb: FormBuilder, 
  	private tostr: ToastrService,
  	private contentService: ContentService) { }

  ngOnInit(): void {
  	this.initForm();
  }

  initForm() {
  	this.contactForm = this.fb.group({
  		name: new FormControl('', Validators.required),
  		email: new FormControl('', Validators.required),
  		phone: new FormControl('', Validators.required),
  		message: new FormControl('', Validators.required),
  	});
  }

  submitContact() {
  	if(this.contactForm.invalid) {
      this.tostr.warning('Please fill all required fields.')
      return false;
    }

    this.contentService.contactUs(this.contactForm.value).subscribe(response => {
      this.showAlert = true;
    	this.tostr.success('Your query has been successfully submitted. You will be notified very shortly.');
    	this.initForm();
    })
  }


}
